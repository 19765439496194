.our_packages_banner {
    background-image: url(../../../public/banner/bannerbackground/banner4.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.our_packages .card_box {
    /* background: linear-gradient(63deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(39, 58, 59, 1) 100%); */
    padding: 20px;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background: linear-gradient(63deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(39, 58, 59, 1) 100%) padding-box, linear-gradient(33deg, #05d5d952, #f807fc54);
    border: 1px solid #0000;
    border-radius: 20px;

}

.our_packages p {
    font-size: 14px;
    margin: 5px 0px;
}

.our_packages .card_footer .btn_w {
    display: flex;
    align-items: center;
}

.our_packages .btn_uni {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 7px 0px;
    /* margin: 10px 0px !important; */
}

.our_packages h4 {
    text-align: center;
    font-weight: 300;
}

.our_packages h5 {
    font-weight: 300;
}

.our_packages .col-md-4 .card_box .text-white {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

.our_packages .col-md-4 .card_box .card_footer {}

.our_packages .card_footer p {
    font-family: "Oswald", sans-serif;
}

.our_packages .icon {
    color: white;
}


.our_packages .card_box ul {
    font-size: 14px;
    color: rgba(215, 215, 215, 1) !important;
    font-weight: 300 !important;
    font-family: "Montserrat", sans-serif;
    padding-left: 25px;
}

.our_pack_head {
    position: relative;
}

.lear::after {
    background: linear-gradient(to right, #05D6D9, #F907FC);
    content: "";
    height: 1px;
    width: 68px;
    position: absolute;
    bottom: -2px;
    left: -3px;
}

.buy_now .lear::after {
    background: linear-gradient(to right, #05D6D9, #F907FC);
    content: "";
    height: 1px;
    width: 110px;
    position: absolute;
    bottom: -2px;
    left: -3px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1000px) {
    .our_packages .col-md-4 .card_box .card_footer {
        display: block;
    }
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .our_packages .col-md-4 .card_box .card_footer {
        display: block;
    }
}

@media screen and (min-device-width: 200px) and (max-device-width: 1000px) {
    .our_packages .btn_uni {
        width: 75%;
    }

    .our_packages .card_footer .btn_w {
        justify-content: center;
    }
}