.nftdetail li {
    display: inline-block;
    width: 25%;
    position: relative;
}
.nftdetail li::after {
    content: "";
    position: absolute;
    right: 30%;
    width: 1px;
    bottom: 0;
    top: 0;
    height: 55px;
    background: rgba(215,215,215,0.2);
}
.nftdetail li::after:last-child{
 display: none;
}
.nftdetail li:last-child::after {
    display: none;
}
.custombtn {
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.30) 0.3%, rgba(249, 7, 252, 0.30) 99.65%);
    backdrop-filter: blur(2px);
    border: none;
    font-family: "Oswald", sans-serif;
}
/* -------------------- */
@media only screen and (max-width: 1200px) {
    body .nftdetail li::after {
        right: 15%;
    }
   
  }
  @media only screen and (max-width: 991px) {
    body .nftdetail li {
    width: 50%;
    margin-bottom: 15px;
}}
@media only screen and (max-width: 425px) {
    .nfts_details img {
        width: 100%;
        object-fit: contain;
    }
    .nftdetail li::after {
        display: none;
    }}