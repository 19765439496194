.aboutUs_bg {
    background-image: url(../../../public/AboutUsbanner.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.aboute_us .footer {
    padding: 0px !important;
}

.aboute_us img {
    /* height: 30px;
    max-width: auto; */

    height: 30px;
    max-width: auto;
    object-fit: contain;
    aspect-ratio: 4 / 1;
}

.aboute_us .card_box img {
    aspect-ratio: 1 / 1;
}

.card_box {
    background: rgba(23, 25, 28, 1);
    border-radius: 10px;
    border: 1px solid #383838;
    padding: 12px 10px;
}

.aboute .faq .accordion-item {
    margin: 20px 16px !important;
}

.our_partner {
    background-image: url(../../../public/ourPartner.png) !important;
    background-size: contain;
    padding: 25px 40px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@media screen and (min-device-width: 200px) and (max-device-width: 700px) {
    .poster-contain h1 {
        font-size: 28px;
    }

    .poster-contain p {
        font-size: 12px;
    }
   
}

@media screen and (min-device-width: 700px) and (max-device-width: 880px) {
    .poster-contain h1 {
        font-size: 34px;
    }

    .poster-contain p {
        font-size: 12px;
    }
   
}