.dashboard_home .box {
    border: 1px solid #303030;
    border-radius: 24px;
    background: rgba(23, 25, 28, 1);
    padding: 55px 20px;
    height: 100%;
}

.dashboard_home .box {
    display: flex;

}

.dashboard_home .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 25px;
    height: 45px;
    width: 45px;
    color: white;
    background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.4) 0.3%, rgba(249, 7, 252, 0.4) 99.65%);
}

.dropdown-item:hover,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #323232 !important;
}

.css-1wvake5.ps-collapsed {
    width: 70px !important;
    min-width: 70px !important;
}

.dashboard_icn {
    height: 45px;
    width: auto;
    object-fit: contain;
}