/* src/BottomNavbar.css */
.bottom-navbar {
  position: fixed;
  border-top: 1px solid rgba(215, 215, 215, 0.20);
  background-color: #222325;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  left: 0;
  justify-content: space-around;
  padding: 8px 0;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  right: 0;
}

.bottom-navbar a img {
  margin-bottom: 1px;
  height: 23px;
  object-fit: contain;
  width: 23px;
}

.menuItem {
  line-height: 1.4;
  padding: 8px 5px;
  width: 63px;
  border-radius: 8px;
  height: 54.19px;
}

.menuItem.active {
  background: linear-gradient(45deg, #1e44502e, #b81fd345);
}

.menuItem:hover {
  background: linear-gradient(45deg, #1e44502e, #b81fd345);
}


.bottom-navbar a {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-navbar a.active {
  color: #4CAF50;
}

.bottom-navbar a span {
  font-size: 10px;
  margin-top: 0.2rem;
}

#outlet::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 768px) {
  .bottom-navbar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }

  #outlet {
    padding-bottom: 86px !important;
  }

  .marginL25,
  .marginL250 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 992px) {
  .home-bottom-navbar {
    display: flex;
  }
}