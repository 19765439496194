.sc-blmEgr.ceTAjv {
    font-size: 22px;
    font-weight: 600;
    margin-top: 11px;
    margin-bottom: 2px;
    font-family: "Oswald", sans-serif;
}

.rdt_TableHeader {
    background: #17191c !important;
    margin-bottom: 10px;
    color: rgb(219, 219, 219) !important;
    margin-bottom: 0px;
}

.sc-eTNRI.lnOUep {
    font-size: 18px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
}

button.btn.btn-success {
    font-size: 14px;
}

.erkbiK div:first-child {
    font-size: 14px;
}

.rdt_Pagination {
    background-color: #17191c !important;
    color: rgb(219, 219, 219) !important;
}

.sc-iuOOrT.hDHZZh {
    background: #17191c !important;
    color: white !important;
}

.ichLPD, .bUaNdX {
    background: (23, 25, 28, 1) !important;
}

.kuPCgf:disabled , .sc-gjLLEI svg, .sc-koYCI svg{
    cursor: unset;
    color: white !important;
    fill: white !important;
}

.dEgWir:disabled {
    cursor: unset;
    color: white !important;
    fill: white !important;
}