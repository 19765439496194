.dashboard {
    background: black;
}

a.ps-menu-button.ps-active,
li.ps-menuitem-root.css-kh81go {
    background: rgba(23, 25, 28, 1) !important;
    color: rgba(11, 209, 217, 1) !important;
}

#outlet {
    height: 100vh;
    overflow-y: scroll;
    background: #121314;
}

.sidebar {
    height: 100vh;
    background: rgba(23, 25, 28, 1);
    position: fixed !important;
    top: 0;
    z-index: 9;
    width: 250px;
    border-right: 1px solid #3c4042 !important;
}

.logOut {
    position: fixed;
    bottom: 10px;
    /* z-index: 15; */
    color: white;
    font-family: "Oswald", sans-serif;
    font-size: 20px;
    left: 32px;
    text-align: center;
}

.css-dip3t8 {
    background-color: rgba(23, 25, 28, 1) !important;
}

li.ps-menuitem-root {
    position: relative;
}

li.ps-menuitem-root.ps-active.css-qyrmmo::after {
    content: '';
    background: linear-gradient(to right, #13c9da,
            #7b73ea, #b641f3, #e11af8);
    position: absolute;
    height: 40px;
    border-radius: 4px 0px 0px 4px;
    width: 5px;
    right: 0px;
    top: 8px;

}

.sidebar_header {
    padding: 13px 20px;
    background: rgba(23, 25, 28, 1);
    height: 55px;
    /* text-align: center; */
    margin-left: 30px;
    padding: 20px 0px;
}

.sidebar_header img {
    height: 25px;
    width: auto;
}

.sidebar_header2 {
    height: 55px;
    text-align: center;
    margin-top: 5px;
}

.sidebar_header2 img {
    height: 45px;
    width: auto;
}

a.ps-menu-button {
    background: rgba(23, 25, 28, 1) !important;
}

a.ps-menu-button:hover {
    background-color: rgba(23, 25, 28, 1) !important;
    color: rgba(11, 209, 217, 1) !important;
}

span.ps-menu-label.css-12w9als {
    font-family: "Oswald", sans-serif;
}

span.ps-menu-icon.css-wx7wi4 {
    font-size: 20px;
}

.marginL250 {
    margin-left: 250px;
    transition: 0.3s;
}

.marginL25 {
    margin-left: 70px;
    transition: 0.3s;
}

/* @media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .marginL250 {
        width: 0px !important;
    }
} */