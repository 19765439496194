.text-primary {
    --bs-primary-rgb: 38, 186, 222;
}

.btn_fix {
    /* position: fixed;
    bottom: 52px;
    right: 5.16%; */

    background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important;

    position: fixed;
    /* bottom: 52px; */
    bottom: 83px;
    right: 30px;
}

.btn_fix:hover {
    box-shadow: 0px 0px 13px 2px gray !important;
}

.total_nfts .btn_uni {
    padding: 6px 18px;
}

.nfts .btn_uni {
    padding: 6px 16px;
}

.nfts img {
    width: 100%;
    height: auto;
}

.nfts .btn_fix .btn_uni {
    padding: 7px 10px;
}

.counter {
    margin-top: 10px;
    color: white;
    display: flex;
    justify-content: space-around;
}

.minus,
.plus {
    color: white;
    border: 1px solid #444444 !important;
    border-radius: 7px;
    background: black !important;
}

.counter button:nth-child(2) {
    width: 50px;
}

.counter button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: rgba(125, 112, 234, 0.4);
    color: white;
    border: 2px solid #6e6e6e;
    border-radius: 7px;
}

ul#myTab,
ul#myTab_perent {
    border-bottom: none;
}

.nav-tabs .nav-item button {
    background: black;
    border: 1px solid rgb(77, 77, 77);
    border-radius: 8px;
    color: white;
    font-size: 14px;
    padding: 8px 50px;
    font-family: "Oswald", sans-serif;
}

.nav-tabs .active {
    /* background: linear-gradient(to right, #13c9da, #7b73ea, #b641f3, #e11af8) !important; */
    background: rgb(11 209 217 / 20%) !important;
    /* border: none !important; */
    border: 1px solid rgba(11, 209, 217, 0.3) !important;
    border-radius: 8px !important;
    /* color: white !important; */
    color: rgba(11, 209, 217, 1) !important;
    font-size: 14px !important;
    padding: 8px 50px !important;
    font-family: "Oswald", sans-serif;
}

.nfts .nav-tabs .perent>.active,
.nfts.dropdown .show {
    background: rgb(11 209 217 / 21%);
    border: 1px solid rgba(11, 209, 217, 1);
    color: rgba(11, 209, 217, 1);
}

.nfts .nfts_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nfts .icon {
    font-size: 18px;
    display: flex;
}

ul.dropdown-menu.bg-dark.show {
    padding: 5px 20px;
    width: 250px;
    background: rgba(18, 19, 20, 1) !important;
    border: 1px solid rgb(104, 104, 104) !important;
    height: 251px;
    overflow-y: scroll;
}

ul.dropdown-menu.bg-dark.show::-webkit-scrollbar {
    display: none;
}

.nfts .btn_uni {
    border-radius: 12px !important;
    /* width: 100%; */
    display: flex;
    justify-content: center;
}

.dropdown li {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.fltrnft {
    display: flex;
    justify-content: end;
    position: relative;
}

.icnsrch {
    color: white;
    position: absolute;
    font-size: 20px;
    left: 3%;
    top: 1px;
}

.t_nftsHeader p {
    font-weight: 600 !important;
}

a.dropdown-item.btn_uni.m-2 span:nth-child(1) {
    font-size: 14px;
    display: flex;
    align-items: center;
}

a.dropdown-item.btn_uni.m-2 span.icon {
    font-size: 20px;
}

.dropdown button.show {
    background: red !important;
}

button#dropdownMenuButton1 {
    border-radius: 12px;
    font-weight: 300;
    color: rgba(11, 209, 217, 1);
    background: rgba(11, 209, 217, 0.1) !important;
    border: 1px solid #444141;
    font-family: "Oswald", sans-serif;
}


/* ===========================Nft details start============================= */

.nfts_details img {
    height: 100%;
    max-width: 100%;
}

.nfts_details .dtl {
    background: #153e42;
    padding: 5px 30px;
    border-radius: 2px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
}

.cardDetail small {
    font-family: "Oswald", sans-serif;
    opacity: 0.8;
    font-size: 12px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardDetail {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* padding: 10px 14px; */
    padding: 5px 8px;
    line-height: 1;
}

.t-price {
    position: absolute;
    width: 100%;
    top: 10px;
    padding: 7px;
    font-family: "Oswald", sans-serif;
}

.cardInnerDetail {
    /* margin-bottom: -9px; */
    margin-bottom: 5px;
}

.cardInnerDetail h5.mb-0 {
    margin: 3px 0px !important;
}

/* ======================= media query ===================== */
@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    li.nav-item {
        margin-top: 10px;
    }

    /* .counter button {
        font-size: 14px;
        width: 30px;
        height: 30px;
        border: 1px solid #6e6e6e;
    } */

    .nfts_details .btn_uni {
        width: 100% !important;
    }

    .btn_fix {
        bottom: 161px;
        right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .cardDetail {
        line-height: 2;
        padding: 3px 30px;
    }

    .cardInnerDetail {
        margin-bottom: -5px;
    }
}

@media only screen and (max-width: 360px) {
    .cardDetail {
        /* line-height: 1.5; */
        line-height: 1;
        padding: 8px 30px;
    }

    .t-price {
        padding: 5px;
    }
}

/* small.position-absolute */
.bonus_tag_2 {
    position: absolute;
    left: 0px;
    /* top: 0px; */
    font-size: 12px;
    top: 0px;
    background: linear-gradient(to right, #05d6d996, #f907fc73) border-box !important;
    padding: 2px 12px;
    padding-left: 8px;

    border-radius: 7px 12px 12px 0px;
}

.bonus_tag {
    position: absolute;
    left: 0px;
    /* top: 0px; */
    font-size: 12px;
    background: linear-gradient(to right, #05d6d996, #f907fc73) border-box !important;
    padding: 2px 12px;
    padding-left: 8px;
    font-style: italic;
    border-radius: 7px 12px 12px 0px;
}


@media (max-width:456px) and (min-width:360px) {
    .cardInnerDetail2 {
        margin-bottom: 0px !important;
    }
}

.nft-search {
    padding-left: 40px;
}

.check_out_btn {
    justify-content: center;
    width: 15%;
}

.nft_flag_img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nft_flag_img img,
.fltr_imgs {
    /* margin-right: 5px; */
    margin-right: 2px;
    width: 17px !important;
    height: 17px !important;
    object-fit: cover;
    border-radius: 50%;
}


/* ------------------------- */

@media screen and (min-device-width: 200px) and (max-device-width: 455px) {
    .cardInnerDetail h5.mb-0 {
        font-size: 18px;
    }

    .cardDetail small {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nft_flag_img img,
    .fltr_imgs {
        width: 15px !important;
        height: 15px !important;
    }

    .nav-tabs .active,
    .nav-tabs .nav-item button {
        padding: 6px 0px !important;
        width: 95%;
    }

    .total_nfts li.nav-item {
        width: 50%;
    }
}

/* / === packages all === / */
.bonus_nft_flag {
    display: flex;
}

.bonus_nft_flag div {
    font-size: 16px;
}

.bonus_nft_flag div b {
    margin-right: 10px;
    font-size: 16px;
    background: #007800;
    padding: 4px 14px;
    border-radius: 8px;
}

.dtable::-webkit-scrollbar {
    width: 5px;
    height: auto;
    background-color: #1F222A;
}

.dtable::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.50) 0.3%, rgba(249, 7, 252, 0.50) 99.65%);
    border-radius: 50px;
}

@media (max-width:768px) {
    .nx-row {
        width: 100%;
    }

    .nx-btn {
        height: 35px;

    }
}